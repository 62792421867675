import './anclado.css'
export default function ComentarioAnclado({ expediente }) {
    return (
        <section className='ancladoContainer'>
            <p className='textoMensajeAnclado'>{expediente.anclado}</p>
            <div className="d-flex justify-content-end">
                <p className="textoMensajeAnclado">{`${expediente.ancladoUser} - ${expediente.ancladoFecha}`}</p>
            </div>
        </section>
    )
}