import { useContext, useEffect, useState } from "react";
import { editarContext } from "../../../context/Editar";
import { useCookies } from 'react-cookie';
import axios from "axios";
export default function SeccionRequeridoLeer({ expediente }) {
    const divLeer = { height: '35px', width: '100%', backgroundColor: 'white' }
    const bot3 = { background: '#F99F41 0% 0% no-repeat padding-box', borderRadius: '22px', height: '41px', width: '180px', }
    const aL = { textDecoration: 'none', color: 'white' }
    const {  setEditar } = useContext(editarContext)
    const [cookies] = useCookies(['user']);
    const hora = localStorage.getItem('hora');
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const editarFunction = async () => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setEditar(true)
    }
    const editarBtn = { background: '#0731FF 0% 0% no-repeat padding-box', borderRadius: '22px', width: '135px', height: '39px' }
    return (
        <div>
            <div>
                <div className="mt-1 mb-3">
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Nombre Requerido</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.nombreRequerido}</div>
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Nombre del Conductor Requerido</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.nombreConductorRequerido}</div>
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Dominio Requerido</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.patenteRequerido}</div>
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Marca/Modelo Requerido</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.marcaModeloRequerido}</div>
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Compañia Requerido</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.compania}</div>
                        </div>
                        {cookies.user.cargo !== 'PAS' ? <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Chat Callbell Requerido</label>
                            <div type="text" class="form-control" style={divLeer}>
                                {expediente.callbellRequerido}
                            </div>
                        </div> : <div></div>}
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">D.N.I Requerido</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.dniRequerido}</div>
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Telefono Requerido</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.telefonoContacto}</div>
                        </div>

                    </div>
                    <div class="mb-2 row">
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Nro de Siniestro Requerido</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.nroSeguimientoCompaniaRequerido}</div>
                        </div>
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Nro de Reclamo del Requerido</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.nroReclamoCompaniaRequerido}</div>
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Nro de Reclamo Cia Requerido</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.polizaRequerido}</div>
                        </div>
                    </div>
                </div>
                {cookies.user.cargo !== 'PAS' ? <div className="row mt-1 mb-3">
                    <div className="col d-flex">
                        <div className="center" style={bot3}>
                            <a
                                style={aL}
                                href={`https://dash.callbell.eu/chat/${expediente.callbellRequerido}?filter=all`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Callbell Requerido
                            </a>
                        </div>
                    </div>
                    <div className="col">
                            <div className="ms-5">
                                <button className="btn btn-primary btn-sm" onClick={async (e) => editarFunction()} style={editarBtn}>EDITAR</button>
                            </div>
                        </div>
                </div> : <div></div>}
            </div>
        </div>
    )
}