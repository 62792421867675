import NivelMediacion from './NivelMediacion';
import EstadoMediacion from './EstadoMediacion';
import '../../App.css'
export default function MiniTablaMediacion({ expediente }) {
    return (
        <div>
            <div className='miniExpContainerMediacion'>{`EXP ${expediente.nro} - ${expediente.nombre} C/ ${expediente.compania} - ${expediente.productorAsociado}`}</div>
            <div class="containerTituloExp ps-3">
                <NivelMediacion nivel={expediente.nivel} />
                <div class="elementosTituloExp">
                    {expediente.estado === 'MEDIACION' ? <div className=''>
                        <h6 className={expediente.etapaMediacion === 1 ? 'estadoATabla center' : (expediente.etapaMediacion === 2 ? 'estadoBTabla center' : (expediente.etapaMediacion === 3 ? 'estadoCTabla center' : (expediente.etapaMediacion === 4 ? 'estadoDTabla center' : (expediente.etapaMediacion === 5 ? 'estadoETabla center' : (expediente.etapaMediacion === 6 ? 'estadoFTabla center' : (expediente.etapaMediacion === 7 ? 'estadoGTabla center' : 'estadoH2Tabla center'))))))}>{expediente.etapaMediacion === 1 ? 'SOLICITUD DE FECHA' : (expediente.etapaMediacion === 2 ? 'FECHA ASIGNADA' : (expediente.etapaMediacion === 3 ? 'NEGOCIACION' : (expediente.etapaMediacion === 4 ? 'REVISIONES LEGALES' : (expediente.etapaMediacion === 5 ? 'ARMADO DERIVACION' : (expediente.etapaMediacion === 6 ? 'ESPERA CONFIRMACION' : (expediente.etapaMediacion === 7 ? 'ACEPTACION MEDIACION' : 'Sin Etapa'))))))}</h6>
                    </div> : <EstadoMediacion estado={expediente.estado} />}
                </div>
                <div class="elementosTituloExp"><p className='textMiniTablaMediacion m-0'>{expediente.asignadoA}</p></div>
                <div class="elementosTituloExp"><p className='textMiniTablaMediacion m-0'>Ult Mod {expediente.ultimaMod}</p></div>
                <div class="elementosTituloExp">
                    <div className='d-flex'>
                        <p className='textMiniTablaMediacion m-0'>{`Stro:\xa0`}</p>
                        <p className='textMiniTablaMediacion m-0'>{expediente.nroSeguimientoCompaniaRequerido}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}