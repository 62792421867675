import "./Header.css";
import logo from "../../images/logoTuveUnChoque.png";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useEffect, useState } from "react";
export default function Header() {
    const link = { textDecoration: "none" };
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    const [exp, setExp] = useCookies(
        ["expNovedades"],
        ["expMediacion"],
        ["exp"],
        ["expActual"]
    );
    const [ticketA, setTicketA] = useCookies(["exp"]);
    const [cont, setCont] = useCookies(["contadorExp"]);
    const navigate = useNavigate();
    const hora = localStorage.getItem("hora");
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(":").map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(":").map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const ticket = async () => {
        await axios.post(
            "https://api.tuveunchoque.com.ar/api/registroDeUso/update",
            {
                user: cookies.user.nombre,
                segundos: getTimeDifferenceInSeconds(formatTime(time), hora),
            }
        );
        localStorage.setItem("hora", formatTime(time));
        setTicketA("exp", []);
        setCont("contadorExp", 0);
        window.location.reload();
    };
    const cerrar = async (e) => {
        e.preventDefault();
        await axios.post(
            "https://api.tuveunchoque.com.ar/api/registroDeUso/update",
            {
                user: cookies.user.nombre,
                segundos: getTimeDifferenceInSeconds(formatTime(time), hora),
            }
        );
        localStorage.setItem("hora", formatTime(time));
        removeCookie("contadorExp");
        removeCookie("contadorExpMediacion");
        removeCookie("exp");
        removeCookie("expMediacion");
        removeCookie("gestion");
        removeCookie("tabla");
        removeCookie("tablaGerencia");
        removeCookie("ticket");
        removeCookie("user");
        removeCookie("expNovedades");
        removeCookie("expActual");
        localStorage.removeItem("user");
        navigate("/login");
        window.location.reload();
    };
    const registro = async (e) => {
        await axios.post(
            "https://api.tuveunchoque.com.ar/api/registroDeUso/update",
            {
                user: cookies.user.nombre,
                segundos: getTimeDifferenceInSeconds(formatTime(time), hora),
            }
        );
        localStorage.setItem("hora", formatTime(time));
        setTicketA("exp", []);
        setCont("contadorExp", 0);
        window.location.reload();
    };
    const [nro, setNro] = useState({ nro: "" });

    // Navegador
    const indexNovedades = Array.isArray(exp.expNovedades)
        ? exp.expNovedades.indexOf(Number(exp.expActual))
        : -1;
    const atrasNovedades = indexNovedades - 1;
    const adelanteNovedades = indexNovedades + 1;

    const indexExp = Array.isArray(exp.exp)
        ? exp.exp.indexOf(Number(exp.expActual))
        : -1;
    const atrasExp = indexExp - 1;
    const adelanteExp = indexExp + 1;

    const indexMed = Array.isArray(exp.expMediacion)
        ? exp.expMediacion.indexOf(Number(exp.expActual))
        : -1;
    const atrasMed = indexMed - 1;
    const adelanteMed = indexMed + 1;

    const prevNovedades = async () => {
        await axios.post(
            "https://api.tuveunchoque.com.ar/api/registroDeUso/update",
            {
                user: cookies.user.nombre,
                segundos: getTimeDifferenceInSeconds(formatTime(time), hora),
            }
        );
        // await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/expedientes/quitarAsignado2`,{nro: exp.exp[atrasNovedades],bolean: 0,})
        localStorage.setItem("hora", formatTime(time));
        setCont("contadorExpNovedades", Number(cont.contadorExpNovedades));
        setExp("expActual", exp.expNovedades[atrasNovedades]);
        navigate(`/expediente/${exp.expNovedades[atrasNovedades]}`);
        window.location.reload();
    };
    const nextNovedades = async () => {
        await axios.post(
            "https://api.tuveunchoque.com.ar/api/registroDeUso/update",
            {
                user: cookies.user.nombre,
                segundos: getTimeDifferenceInSeconds(formatTime(time), hora),
            }
        );
        // await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/expedientes/quitarAsignado2`,{nro: exp.exp[adelanteNovedades],bolean: 0,})
        localStorage.setItem("hora", formatTime(time));
        setCont("contadorExpNovedades", Number(cont.contadorExpNovedades));
        setExp("expActual", exp.expNovedades[adelanteNovedades]);
        navigate(`/expediente/${exp.expNovedades[adelanteNovedades]}`);
        window.location.reload();
    };

    const prevExp = async () => {
        await axios.post(
            "https://api.tuveunchoque.com.ar/api/registroDeUso/update",
            {
                user: cookies.user.nombre,
                segundos: getTimeDifferenceInSeconds(formatTime(time), hora),
            }
        );
        localStorage.setItem("hora", formatTime(time));
        setCont("contadorExp", Number(cont.contadorExp));
        setExp("expActual", exp.exp[atrasExp]);
        navigate(`/expediente/${exp.exp[atrasExp]}`);
        window.location.reload();
    };
    const nextExp = async () => {
        await axios.post(
            "https://api.tuveunchoque.com.ar/api/registroDeUso/update",
            {
                user: cookies.user.nombre,
                segundos: getTimeDifferenceInSeconds(formatTime(time), hora),
            }
        );
        localStorage.setItem("hora", formatTime(time));
        setCont("contadorExp", Number(cont.contadorExp));
        setExp("expActual", exp.exp[adelanteExp]);
        navigate(`/expediente/${exp.exp[adelanteExp]}`);
        window.location.reload();
    };

    const prevMed = async () => {
        await axios.post(
            "https://api.tuveunchoque.com.ar/api/registroDeUso/update",
            {
                user: cookies.user.nombre,
                segundos: getTimeDifferenceInSeconds(formatTime(time), hora),
            }
        );
        localStorage.setItem("hora", formatTime(time));
        setCont("contadorExp", Number(cont.contadorExp));
        setExp("expActual", exp.expMediacion[atrasMed]);
        navigate(`/expediente/${exp.expMediacion[atrasMed]}`);
        window.location.reload();
    };
    const nextMed = async () => {
        await axios.post(
            "https://api.tuveunchoque.com.ar/api/registroDeUso/update",
            {
                user: cookies.user.nombre,
                segundos: getTimeDifferenceInSeconds(formatTime(time), hora),
            }
        );
        localStorage.setItem("hora", formatTime(time));
        setCont("contadorExp", Number(cont.contadorExp));
        setExp("expActual", exp.expMediacion[adelanteMed]);
        navigate(`/expediente/${exp.expMediacion[adelanteMed]}`);
        window.location.reload();
    };

    const borrarNavegacion = async (e) => {
        e.preventDefault()
        setCookie('exp', JSON.stringify([]), { path: '/' })
        setCookie('expMediacion', JSON.stringify([]), { path: '/' })
        setCookie('expNovedades', JSON.stringify([]), { path: '/' })
    }
    return (
        <header className={cookies.user ? "header" : ""}>
            {cookies.user ? (
                <>
                    {cookies.user.cargo === "GERENCIA" ||
                        cookies.user.cargo === "EMPLEADO" ||
                        cookies.user.cargo === "AGENTE" ? (
                        <section className="sectionHeader">
                            <div className="d-flex center">
                                <div className="">
                                    {cookies.user?.cargo === "PAS" ||
                                        cookies.user.cargo === "MEDIADOR" ||
                                        cookies.user.cargo === "ORGANIZACION" ? (
                                        <Link to="/expedientes">
                                            <img src={logo} alt="logo" className="logo" />
                                        </Link>
                                    ) : (
                                        <Link to="/gestion" onClick={ticket}>
                                            <img src={logo} alt="logo" className="logo" />
                                        </Link>
                                    )}
                                </div>
                                <div className="ms-5">
                                    {exp.expNovedades.length >= 0 ? (
                                        <div className="d-flex">
                                            {indexNovedades > 0 ? (
                                                <button
                                                    className="btnFlechaNovedadesAtras"
                                                    onClick={prevNovedades}
                                                >
                                                    {exp.expNovedades[atrasNovedades]}
                                                </button>
                                            ) : null}
                                            {indexNovedades >= 0 ? (
                                                <button
                                                    className="btnFlechaNovedadesSiguiente"
                                                    onClick={nextNovedades}
                                                >
                                                    {exp.expNovedades[adelanteNovedades]}
                                                </button>
                                            ) : null}
                                        </div>
                                    ) : exp.exp.length > 0 ? (
                                        <div className="d-flex">
                                            {indexNovedades > 0 ? (
                                                <button
                                                    className="btnFlechaNovedadesAtras"
                                                    onClick={prevExp}
                                                >
                                                    {exp.exp[atrasExp]}
                                                </button>
                                            ) : null}
                                            {indexNovedades > 0 ? (
                                                <button
                                                    className="btnFlechaNovedadesSiguiente"
                                                    onClick={nextExp}
                                                >
                                                    {exp.exp[adelanteExp]}
                                                </button>
                                            ) : null}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="ms-5">
                                    {exp.expMediacion.length > 0 ? (
                                        <div className="d-flex">
                                            <button
                                                className="btnFlechaNovedadesAtrasMed"
                                                onClick={prevMed}
                                            >
                                                {exp.expMediacion[atrasMed]}
                                            </button>
                                            <button
                                                className="btnFlechaNovedadesSiguienteMed"
                                                onClick={nextMed}
                                            >
                                                {exp.expMediacion[adelanteMed]}
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="d-flex center">
                                <div className="tooltip-container">
                                    <button className='btn' onClick={(e) => borrarNavegacion(e)}>
                                        <i className="bi bi-x"></i>
                                    </button>
                                    <span className="tooltip-text">Borrar datos de navegación</span>
                                </div>
                                <input
                                    type="number"
                                    placeholder="Buscar Nro"
                                    className="form-control form-control-sm"
                                    style={{ height: "5px", width: "120px" }}
                                    onChange={(e) =>
                                        setNro((prevState) => ({
                                            ...prevState,
                                            nro: e.target.value,
                                        }))
                                    }
                                    onKeyDown={async (e) => {
                                        if (e.key === "Enter") {
                                            await axios.post(
                                                "https://api.tuveunchoque.com.ar/api/registroDeUso/update",
                                                {
                                                    user: cookies.user.nombre,
                                                    segundos: getTimeDifferenceInSeconds(
                                                        formatTime(time),
                                                        hora
                                                    ),
                                                }
                                            );
                                            setExp("expActual", nro.nro);
                                            localStorage.setItem("hora", formatTime(time));
                                            navigate(`/expediente/${nro.nro}`);
                                            window.location.reload();
                                        }
                                    }}
                                />
                                <nav class="navbar d-flex">
                                    <div class="container-fluid">
                                        <button
                                            class="navbar-toggler"
                                            type="button"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasNavbar"
                                            aria-controls="offcanvasNavbar"
                                        >
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                        <div
                                            class="shadow offcanvas offcanvas-end"
                                            tabindex="-1"
                                            id="offcanvasNavbar"
                                            aria-labelledby="offcanvasNavbarLabel"
                                        >
                                            <div class="offcanvas-header">
                                                <h5
                                                    class="offcanvas-title"
                                                    id="offcanvasNavbarLabel"
                                                ></h5>
                                                <button
                                                    type="button"
                                                    class="btn-close text-reset"
                                                    data-bs-dismiss="offcanvas"
                                                    aria-label="Close"
                                                ></button>
                                            </div>
                                            <div class="offcanvas-body  mt-3 m-auto">
                                                <div className="ps-3">
                                                    <h6 className="textoNombreHeader ps-3">
                                                        {cookies.user.nombre}
                                                    </h6>
                                                    <div className="botonCerrarSesion center text-center">
                                                        <button
                                                            className="cerrarSesion"
                                                            onClick={(e) => cerrar(e)}
                                                        >
                                                            Cerrar Sesión
                                                        </button>
                                                    </div>
                                                </div>
                                                {cookies.user ? (
                                                    <div className="mt-5">
                                                        {cookies.user.cargo !== "PAS" &&
                                                            cookies.user.cargo !== "MEDIADOR" &&
                                                            cookies.user.cargo !== "ORGANIZACION" &&
                                                            cookies.user.cargo !== "AGENTE" ? (
                                                            <Link
                                                                to={"/novedades"}
                                                                style={link}
                                                                onClick={(e) => registro(e)}
                                                            >
                                                                <div className="botonHeader d-flex justify-content-start align-items-center">
                                                                    <p className="textoBotonHeader m-0">
                                                                        NOVEDADES
                                                                    </p>
                                                                </div>
                                                            </Link>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        {cookies.user.cargo !== "PAS" &&
                                                            cookies.user.cargo !== "MEDIADOR" &&
                                                            cookies.user.cargo !== "ORGANIZACION" ? (
                                                            <Link
                                                                to={"/gestion"}
                                                                style={link}
                                                                onClick={(e) => registro(e)}
                                                            >
                                                                <div className="botonHeader d-flex justify-content-start align-items-center">
                                                                    <p className="textoBotonHeader m-0">
                                                                        GESTION
                                                                    </p>
                                                                </div>
                                                            </Link>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        {cookies.user.cargo !== "PAS" &&
                                                            cookies.user.cargo !== "MEDIADOR" &&
                                                            cookies.user.cargo !== "ORGANIZACION" ? (
                                                            <Link
                                                                to={"/expedientes"}
                                                                style={link}
                                                                onClick={(e) => registro(e)}
                                                            >
                                                                <div className="botonHeader d-flex justify-content-start align-items-center">
                                                                    <p className="textoBotonHeader m-0">TABLA</p>
                                                                </div>
                                                            </Link>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        {cookies.user.cargo !== "PAS" &&
                                                            cookies.user.cargo !== "MEDIADOR" &&
                                                            cookies.user.cargo !== "ORGANIZACION" &&
                                                            cookies.user.cargo !== "AGENTE" ? (
                                                            <Link
                                                                to={"/mediacion"}
                                                                style={link}
                                                                onClick={(e) => registro(e)}
                                                            >
                                                                <div className="botonHeader d-flex justify-content-start align-items-center">
                                                                    <p className="textoBotonHeader m-0">
                                                                        MEDIACIONES
                                                                    </p>
                                                                </div>
                                                            </Link>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        {cookies.user.crearExpedienteGerencia ? (
                                                            <Link
                                                                to={"/expediente/nuevo"}
                                                                style={link}
                                                                onClick={(e) => registro(e)}
                                                            >
                                                                <div className="botonHeader d-flex justify-content-start align-items-center">
                                                                    <p className="textoBotonHeader m-0">
                                                                        ING EXP
                                                                    </p>
                                                                </div>
                                                            </Link>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        {cookies.user.cargo !== "PAS" &&
                                                            cookies.user.cargo !== "MEDIADOR" &&
                                                            cookies.user.cargo !== "ORGANIZACION" &&
                                                            cookies.user.cargo !== "AGENTE" ? (
                                                            <Link
                                                                to={"/expediente/cobro/nuevo"}
                                                                style={link}
                                                                onClick={(e) => registro(e)}
                                                            >
                                                                <div className="botonHeader d-flex justify-content-start align-items-center">
                                                                    <p className="textoBotonHeader m-0">COBROS</p>
                                                                </div>
                                                            </Link>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        {cookies.user.cargo !== "PAS" &&
                                                            cookies.user.cargo !== "MEDIADOR" &&
                                                            cookies.user.cargo !== "ORGANIZACION" &&
                                                            cookies.user.cargo !== "AGENTE" ? (
                                                            <Link
                                                                to={
                                                                    "https://estudio-pyp.com.ar/formulario-presentacion/"
                                                                }
                                                                style={link}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <div className="botonHeader d-flex justify-content-start align-items-center">
                                                                    <p className="textoBotonHeader m-0">FORMS</p>
                                                                </div>
                                                            </Link>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        {cookies.user.cargo !== "PAS" &&
                                                            cookies.user.cargo !== "MEDIADOR" &&
                                                            cookies.user.cargo !== "ORGANIZACION" &&
                                                            cookies.user.cargo !== "AGENTE" ? (
                                                            <Link
                                                                to={"/estadisticas"}
                                                                style={link}
                                                                onClick={(e) => registro(e)}
                                                            >
                                                                <div className="botonHeader d-flex justify-content-start align-items-center">
                                                                    <p className="textoBotonHeader m-0">GRAPHS</p>
                                                                </div>
                                                            </Link>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        {cookies.user.cargo === "PAS" &&
                                                            cookies.user.crear === true ? (
                                                            <Link
                                                                to={"/nuevoExpPas"}
                                                                style={link}
                                                                onClick={(e) => registro(e)}
                                                            >
                                                                <div className="botonHeader d-flex justify-content-start align-items-center">
                                                                    <p className="textoBotonHeader m-0">
                                                                        CREAR EXP
                                                                    </p>
                                                                </div>
                                                            </Link>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        {cookies.user.cargo === "GERENCIA" ? (
                                                            <Link
                                                                to={"/files"}
                                                                style={link}
                                                                onClick={(e) => registro(e)}
                                                            >
                                                                <div className="botonHeader d-flex justify-content-start align-items-center">
                                                                    <p className="textoBotonHeader m-0">FILES</p>
                                                                </div>
                                                            </Link>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                        {cookies.user.cargo === "GERENCIA" ||
                                                            cookies.user.cargo === "EMPLEADO" ? (
                                                            <Link
                                                                style={link}
                                                                to={"/ajustes"}
                                                                onClick={(e) => registro(e)}
                                                            >
                                                                <button className="btnAjustes mt-3 center">
                                                                    <i class="bi bi-gear ajustes"></i>
                                                                </button>
                                                            </Link>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </section>
                    ) : (
                        <section className="sectionHeader">
                            <Link to="/expedientes">
                                <img src={logo} alt="logo" className="logo" />
                            </Link>
                            {cookies.user.crear === true ? (
                                <Link
                                    to={"/nuevoExpPas"}
                                    style={link}
                                    onClick={(e) => registro(e)}
                                    className="botonHeaderPas"
                                >
                                    CREAR EXP
                                </Link>
                            ) : (
                                <div></div>
                            )}
                        </section>
                    )}
                </>
            ) : (
                <div></div>
            )}
        </header>
    );
}
