import { useState} from "react";
import '../index.css'
import { useCookies } from 'react-cookie';
import SeccionCliente from '../SeccionCliente/SeccionCliente'
import SeccionCobro from '../SeccionCobro/SeccionCobro'
import SeccionExpediente from '../SeccionExpediente/SeccionExpediente'
import SeccionRequerido from '../SeccionRequerido/SeccionRequerido'
import './informacionExpediente.css'
import "react-datepicker/dist/react-datepicker.css";
export default function InformacionExpediente({ expediente }) {
    // VALORES GLOBALES 
    const [active, setActive] = useState(1);
    const SetView = (active) => { setActive(active) };
    const [cookies] = useCookies(['user']);
    // FUNCION CONDICIONAL PESTANIAS
    const ActiveView = () => {
        switch (active) {
            case 1:
                return <SeccionExpediente expediente={expediente} />;
            case 2:
                return <SeccionCliente expediente={expediente} />;
            case 3:
                return <SeccionCobro expediente={expediente} />;
            case 4:
                return <SeccionRequerido expediente={expediente} />;
            case 1:
                return <SeccionExpediente expediente={expediente} />;
            case 2:
                return <SeccionCliente expediente={expediente} />;
            case 4:
                return <SeccionRequerido expediente={expediente} />;
            default:
                return <SeccionExpediente expediente={expediente} />
        }
    };
    return (
        <div className="infoExpContainerAnclado">
            {cookies.user.cargo !== 'PAS' ?
                <div className="d-flex m-0 p-0">
                    <div className={active === 1 ? 'botonActivoPlanillaExp' : 'botonInactivoPlanillaExp'}>
                        <button className={active === 1 ? "btnCambioPlanilla" : "btnCambioPlanillaInactivo"} onClick={() => SetView(1)}>EXPEDIENTE</button>
                    </div>
                    <div className={active === 2 ? 'botonActivoPlanillaExp' : 'botonInactivoPlanillaExp'}>
                        <button className={active === 2 ? "btnCambioPlanilla" : "btnCambioPlanillaInactivo"} onClick={() => SetView(2)}>CLIENTE</button>
                    </div>
                    <div className={active === 3 ? 'botonActivoPlanillaExp' : 'botonInactivoPlanillaExp'}>
                        <button className={active === 3 ? "btnCambioPlanilla" : "btnCambioPlanillaInactivo"} onClick={() => SetView(3)}>COBRO</button>
                    </div>
                    <div className={active === 4 ? 'botonActivoPlanillaExp' : 'botonInactivoPlanillaExp'}>
                        <button className={active === 4 ? "btnCambioPlanilla" : "btnCambioPlanillaInactivo"} onClick={() => SetView(4)}>REQUERIDO</button>
                    </div>
                </div> :
                <div className="d-flex m-0 p-0">
                    <div className={active === 1 ? 'botonActivoPlanillaExp' : 'botonInactivoPlanillaExp'}>
                        <button className={active === 1 ? "btnCambioPlanilla" : "btnCambioPlanillaInactivo"} onClick={() => SetView(1)}>EXPEDIENTE</button>
                    </div>
                    <div className={active === 2 ? 'botonActivoPlanillaExp' : 'botonInactivoPlanillaExp'}>
                        <button className={active === 2 ? "btnCambioPlanilla" : "btnCambioPlanillaInactivo"} onClick={() => SetView(2)}>CLIENTE</button>
                    </div>
                    <div className={active === 4 ? 'botonActivoPlanillaExp' : 'botonInactivoPlanillaExp'}>
                        <button className={active === 4 ? "btnCambioPlanilla" : "btnCambioPlanillaInactivo"} onClick={() => SetView(4)}>REQUERIDO</button>
                    </div>
                </div>}
            {ActiveView()}
        </div>
    )
}