import { useContext, useEffect, useState } from "react";
import { editarContext } from "../../../context/Editar";
import { useCookies } from 'react-cookie';
import axios from "axios";
export default function SeccionClienteLeer({ expediente }) {
    const divLeer = { height: '35px', width: '100%', backgroundColor: 'white' }
    const {setEditar } = useContext(editarContext)
    const hora = localStorage.getItem('hora');
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const editarFunction = async () => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setEditar(true)
    }
    const editarBtn = { background: '#0731FF 0% 0% no-repeat padding-box', borderRadius: '22px', width: '135px', height: '39px' }
    const [cookies] = useCookies(['user']);
    return (
        <div>
            <div>
                <div className="mt-1 mb-3">
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Nombre Cliente</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.nombre}</div>
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Nombre Conductor del Cliente</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.nombreConductorCliente}</div>
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Dominio</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.dominioCliente}</div>
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Pas Asociado</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.productorAsociado}</div>
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Honorarios</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.honorarios}</div>
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Marca/Modelo Cliente</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.marcaModeloCliente}</div>
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Mail</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.mail}</div>
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">DNI</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.dni}</div>
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Telefono</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.telefono}</div>
                        </div>
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Telefono 2</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.telefono2}</div>
                        </div>
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Telefono 3</label>
                            <div type="text" class="form-control" style={divLeer}>{expediente.telefono3}</div>
                        </div>
                    </div>
                </div>
                {cookies.user.cargo !== 'PAS' ? <div className="row mt-1 mb-3">
                    <div className="col d-flex"></div>
                    <div className="col">
                        <div className="ms-5">
                            <button className="btn btn-primary btn-sm" onClick={async (e) => editarFunction()} style={editarBtn}>EDITAR</button>
                        </div>
                    </div>
                </div> : <div></div>}
            </div>
        </div>
    )
}