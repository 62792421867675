import axios from "axios";
import { useCallback, useState, useContext, useEffect } from "react";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import './login.css'
import este from '../images/logoBack.png'
import logo from '../images/logoTuveUnChoque.png'
export default function Login() {
    const [signUpData, setSignUpData] = useState({ email: '', password: '' })
    const [cookies, setCookie, removeCookie] = useCookies(['user'], ['text'], ['ticket']);
    const navigate = useNavigate()
    const [infoTicket, setInfoTicket] = useState({ info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], prioridad: [], plazo: 5, expFrom: null, expTo: null });
    const [infoGestion, setInfoGestion] = useState({ info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], prioridad: [], plazo: 0, expFrom: null, expTo: null });
    const [infoTabla, setInfoTabla] = useState({ info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], patente: '', cliente: '', expFrom: '', expTo: '', estudioAsignado: [] });
    const [infoTablaGerencia, setInfoTablaGerencia] = useState({ info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], vista: true, mesCobradoFrom: '', mesCobradoTo: '', mesEstimadoFrom: '', mesEstimadoTo: '', expFrom: '', expTo: '', modoDePago: '', estadoCobro: '', nroFactura: '', facturadoPor: '', honorariosFrom: '', honorariosTo: '', nroFrom: null, nroTo: null });
    const [userFalse, setUserFalse] = useState(false)
    const dia = new Date().toLocaleDateString('en-GB')
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId); 
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const userSignUpSubmit = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post("https://api.tuveunchoque.com.ar/api/user/log", {email: signUpData.email,password: signUpData.password})
        if (response.data === 'Invalid OAuth') {
            setUserFalse(true)
        } else {
            setCookie('user', response.data, { path: '/' })
            setCookie('ticket', JSON.stringify(infoTicket), { path: '/' })
            setCookie('tabla', JSON.stringify(infoTabla), { path: '/' })
            setCookie('exp', JSON.stringify([]), { path: '/' })
            setCookie('expMediacion', JSON.stringify([]), { path: '/' })
            setCookie('expNovedades', JSON.stringify([]), { path: '/' })
            setCookie('expActual', JSON.stringify([]), { path: '/' })
            setCookie('contadorExp', JSON.stringify(0), { path: '/' })
            setCookie('contadorExpMediacion', JSON.stringify(0), { path: '/' })
            setCookie('tablaGerencia', JSON.stringify(infoTablaGerencia), { path: '/' })
            setCookie('gestion', JSON.stringify(infoGestion), { path: '/' })
            localStorage.setItem('user', response.data.nombre);
            localStorage.setItem('dia', dia)
            localStorage.setItem('hora', formatTime(time))
            if (response.data.cargo === 'MEDIADOR') {
                navigate('/expedientes')
                removeCookie('text')
            } else if (response.data.cargo === 'PAS') {
                navigate('/expedientes')
                removeCookie('text')
            } else if (response.data.cargo === 'ORGANIZACION') {
                navigate('/expedientes')
                removeCookie('text')
            } else if (response.data.cargo === 'AGENTE') {
                navigate('/expedientes')
                removeCookie('text')
            } else {
                const res = await axios.post("https://api.tuveunchoque.com.ar/api/registroDeUso/sesion",{user: response.data.nombre,hora: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toString()})
                navigate('/novedades')
                removeCookie('text')
            }
        }
    }, [signUpData, cookies, setCookie])
    return (
        <main className="mainLogin">
            <img src={este} alt="back" className="backImg" />
            <img src={logo} alt="" className="imgLogin" />
            <form onSubmit={userSignUpSubmit}>
                <div class="userLogin">
                    <input type="text" class="form-control" required value={signUpData.email} className="input w-100" placeholder="USUARIO"
                        onChange={(e) =>
                            setSignUpData((prevState) => ({
                                ...prevState,
                                email: e.target.value,
                            }))} />
                </div>
                <div class="passwordLogin">
                    <input type="password" class="form-control" required value={signUpData.password} className="input w-100" placeholder="CONTRASEÑA"
                        onChange={(e) =>
                            setSignUpData((prevState) => ({
                                ...prevState,
                                password: e.target.value,
                            }))} />
                </div>
                <div className={userFalse === false ? 'd-none' : 'userFalseLogin'}><p className="text-danger">Usuario y/o Contraseña incorrectos</p></div>
                <div className="submitLogin center"><button type="submit" className="btnSub text-white">Iniciar Sesion</button></div>
            </form>
        </main>
    );
}
